import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

import DefaultLayout from "/home/circleci/project/node_modules/gatsby-theme-docz/src/base/Layout.js";
import { Props, Link } from 'docz';
import { BusIcon, BicycleIcon } from '@entur/icons';
import { TravelTag } from '@entur/travel';
import { Playground } from '~/components/playground/Playground';
import PageHeader from '~/components/PageHeader';
import { ImportStatement } from '~/components/ImportStatement';
import { traveltag } from './props';
import './traveltag.css';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component '" + name + "' was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <PageHeader mdxType="PageHeader" />
    <Playground __position={1} __code={'<TravelTag transport=\"bus\">32</TravelTag>'} __scope={{
      props,
      DefaultLayout,
      Props,
      Link,
      BusIcon,
      BicycleIcon,
      TravelTag,
      Playground,
      PageHeader,
      ImportStatement,
      traveltag,
      DefaultLayout,
      _frontmatter
    }} props={traveltag} mdxType="Playground">
  <TravelTag transport="bus" mdxType="TravelTag">32</TravelTag>
    </Playground>
    <h2 {...{
      "id": "komponenter"
    }}>{`Komponenter`}</h2>
    <h3 {...{
      "id": "traveltag"
    }}>{`TravelTag`}</h3>
    <ImportStatement imports="TravelTag" mdxType="ImportStatement" />
    <Props of={TravelTag} mdxType="Props" />
    <p>{`Om du ønsker at man skal kunne lukke / fjerne en TravelTag, kan du sende inn en onClose-callback funksjon.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      